import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.isWeekendActive)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 0,
          class: "fw_700 nowrap",
          localizedKey: "app_QuestoWeekend",
          text: "Questo Weekend"
        }))
      : _createCommentVNode("", true),
    (_ctx.isWeekActive)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 1,
          class: "fw_700 nowrap",
          localizedKey: "app_QuestaSettimana",
          text: "Questa Settimana"
        }))
      : _createCommentVNode("", true),
    (_ctx.isMonthActive)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 2,
          class: "fw_700 nowrap",
          localizedKey: "app_QuestoMese",
          text: "Questo Mese"
        }))
      : _createCommentVNode("", true)
  ], 64))
}