
import { Options, Vue } from 'vue-class-component';
import { AppUserClient, ExperienceClient } from '@/services/Services';
import { ExperiencePeriodVM } from '@/viewModel';
import InfoModal from '@/components/modals/infoModal.vue';
import ExperienceResume from '../components/experience/experienceResume.vue';
import ExperienceEditSuccessModal from '../modals/experience/experienceEditSuccessModal.vue';
import bodymovin, { AnimationItem } from 'lottie-web';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as AudioRecorderServices from '@/services/AudioRecorder/AudioRecorderServices';
import { getFromGoogleFilterAddressVMToAddress } from '@/utils';

@Options({
    components: {
        ExperienceResume
    }
})
export default class ExperienceEdit extends Vue {

    experienceIdentifier: string = "";
    slideIndex: number = 0;
    model: OM.MyExperienceDetailVM = new OM.MyExperienceDetailVM();
    address: OM.UserAddress = null;
    googleFilterAddressVM : VM.GoogleFilterAddressVM = new VM.GoogleFilterAddressVM();
    
    audio: AudioRecorderServices.SaveFileVM = new AudioRecorderServices.SaveFileVM(); 
    categories: OM.ExperienceCategory[] = [];

    loaded: boolean = false;
    showButton: boolean = false;
    isRecording: boolean = false;

    created() {
        this.experienceIdentifier = this.$route.params.id ? this.$route.params.id.toString() : "";
        
        var proms = [];
        proms.push(ExperienceClient.getAllExperienceCategories(false));
        proms.push(AppUserClient.getFilter());

        if(this.experienceIdentifier)
            proms.push(ExperienceClient.getById(this.experienceIdentifier));

        Promise.all(proms)
        .then(xs => {
            this.categories = xs[0];
            this.address = (<OM.FilterVM>xs[1]).currentPosition;

            if(this.experienceIdentifier){
                this.model = xs[2];
                
                this.address = this.model.address;

                if(!this.model.category)
                    this.model.category = new OM.ExperienceCategory();
            }
            else
                this.model.period.title = ExperiencePeriodVM.weekend;
                
            this.loaded = true;

            setTimeout(() => {
                this.showButton = true;
            }, 600);
        })
    }

    mounted(){
        setTimeout(() => {
            var experienceLoop = bodymovin.loadAnimation({
                container: <any>this.$refs.experienceLoop,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/experience_loop.json',
            })

            experienceLoop.play();
        }, 300);
    }

    get isWeekendActive() {
        return this.model.period.title == ExperiencePeriodVM.weekend;
    }
    get isWeekActive() {
        return this.model.period.title == ExperiencePeriodVM.week;
    }
    get isMonthActive() {
        return this.model.period.title == ExperiencePeriodVM.month;
    }
    setWeekendActive() {
        this.model.period.title = ExperiencePeriodVM.weekend;
    }
    setWeekActive() {
        this.model.period.title = ExperiencePeriodVM.week;
    }
    setMonthActive() {
        this.model.period.title = ExperiencePeriodVM.month;
    }

    get categorySelected() {           
        return this.categories.find(x => x.identifier == this.model.category.identifier);
    }

    prev() {
        if(this.slideIndex >= 1) {
            this.slideIndex--;
            this.scrollPagePaddingTop();
        }
    }
    next() {
        this.slideIndex++;
        this.scrollPagePaddingTop();
    }

    scrollPagePaddingTop(){
        var el = this.$refs.page_padding_scrollable as HTMLElement;
        el.scrollTop = 0;
    }

    audioRecorded(audio: AudioRecorderServices.SaveFileVM) {
        this.audio = audio;
        this.next();
    }

    cancelAudio(){
        this.audio = new AudioRecorderServices.SaveFileVM();
        this.model.audioSrc = "";
    }

    exitEdit() {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_DesideriSalvareExperienceBozze", "Desideri salvare l’Experience nelle bozze e completarla in un secondo momento?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.save()
                .then(x => {
                    // this.$router.replace("/experience");
                    this.$router.back();
                })
            },
            denyCb: () => {
                this.$opModal.closeLast();
                this.$router.back();
            }
        })
    }

    updateAudio(audio: AudioRecorderServices.SaveFileVM){
        this.audio = audio;

        if(this.audio.url)
            this.model.audioSrc = "";
    }

    get isDisabled(){
        if(this.slideIndex == 0)
            return !this.model.category.identifier || !this.model.period.title;
        else if(this.slideIndex == 1)
            return !this.model.title;
        else if(this.slideIndex == 2)
            return !this.model.description;

        return !this.model.category.identifier || !this.model.period.title || !this.model.title || !this.model.description;
    }

    publish() {
        this.save()
        .then( x => {
            ExperienceClient.submitForApproval(x)
            .then(x => {
                this.$opModal.show(ExperienceEditSuccessModal, {
                    callback: () => {
                        this.$opModal.closeLast();
                        this.$router.back();
                    }
                });
            })
        })
        .catch( ex => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: ex.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    save(): Promise<string> {
        let prom = new Promise<string>( async (resolve, reject) => {
            
            await this.deleteAudio();

            if(this.googleFilterAddressVM.placeId)
                this.address = await getFromGoogleFilterAddressVMToAddress(this.googleFilterAddressVM);
            
            var modelToSave: OM.ExperienceEditVM = {
                experienceIdentifier: this.model.experienceIdentifier,
                categoryIdentifier: this.model.category.identifier,
                description: this.model.description,
                periodTitle: this.model.period.title,
                title: this.model.title,
                address: this.address
            }

            ExperienceClient.save(modelToSave)
            .then( identifier => {
                ExperienceClient.uploadAudio(identifier, this.audio.file)
                .then( x => {
                    resolve(identifier);
                }).catch(err => {
                    reject(err);
                })
            }).catch(err => {
                reject(err);
            })
        })

        return prom;
    }

    deleteAudio() : Promise<void> {
        let prom = new Promise<void>( (resolve, reject) => {
            if(!this.model.experienceIdentifier || (this.model.experienceIdentifier && this.model.audioSrc))
            {
                resolve();
                return;
            }
            
            ExperienceClient.deleteAudio(this.model.experienceIdentifier)
            .then( x => {
                resolve();
            })
        });

        return prom;
    }

}
