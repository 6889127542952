
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ShowExperiencePeriodSelected from './showExperiencePeriodSelected.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ShowExperiencePeriodSelected
    }
})
export default class ExperienceResume extends Vue {

    @Prop({
        default: () => new OM.MyExperienceDetailVM()
    }) experience: OM.MyExperienceDetailVM;

    @Prop({
        default: () => new OM.ExperienceCategory()
    }) categorySelected: OM.ExperienceCategory;

    @Prop() isSecondChance: boolean;

    @Prop({
        default: ""
    }) audioSrc: string;

    get filteredAudioSrc(){
        if(this.audioSrc)
            return this.audioSrc;

        return this.experience.audioSrc;
    }

    created(){
    }

}
