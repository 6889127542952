
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import bodymovin, { AnimationItem } from 'lottie-web';

@Options({
    components: {}
})
export default class ExperienceEditSuccessModal extends Vue {

    @Prop() callback: any;

    created() {}

    mounted(){
        setTimeout(() => {
            var experienceLoop = bodymovin.loadAnimation({
                container: <any>this.$refs.experienceLoop,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/experience_loop.json',
            })

            experienceLoop.play();
        }, 300);
    }

    goToFaq() {
        this.$opModal.closeLast();
        this.$router.push('/experience/faq');
    }

}
